/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  BASE_URL_MANAGER,
  BASE_URL_ORCHESTATOR,
  BASE_URL_DROPI,
} from "@/config";
import { store } from "@/app/store";
import Axios from "axios";
import { Component } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import AdminNavbar from "@/app/components/admin-navbar/AdminNavbar.vue";
import NavBar from "@/app/components/shared/nav-bar/NavBar.vue";
import FacebookButton from "@/app/components/facebook-button/FacebookButton.vue";
import axios from "axios";

@Component({
  name: "projects-management",
  components: { AdminNavbar, NavBar, FacebookButton },
})
export default class ProjectsManagement extends VueWizard {
  public visible = false;
  public addControl = false;
  public modalShowFlow = false;
  public formLayout = "horizontal";
  public nameVal = "";
  public projectId = "";
  public projectsInfo: any[] = [];
  public projectFlows = "";
  public projectTopics = "";
  public topiContent: {
    id: string;
    nodeList: any[];
    name: string;
    project: string;
    createdAt: string;
    updatedAt: string;
  }[] = [];
  public conversations = "";
  public currentProject: any = {};
  public selectedWhatsappNumber: any = {};
  public whatsappNumbers: any[] = [];
  public isNew = false;

  mounted(): void {
    this.proyectList();
  }

  newProyect(value: any): void {
    Axios.post(
      `${BASE_URL_MANAGER}/project/${
        store.state.user ? store.state.user.user.company._id : ""
      }`,
      { name: value }
    ).then(() => {
      this.proyectList();
      this.addControl = false;
      this.nameVal = "";
      this.$notification.success({
        placement: "bottomRight",
        message: "Datos guardados con exito!",
        description: "Los datos del proyecto han sido guardados",
      });
    });
  }

  cancelNewProyect(): void {
    this.proyectList();
    this.addControl = false;
  }

  showProjectDetails(projectId: any): void {
    console.log("projectId", projectId);

    if (isNaN(projectId)) {
      this.currentProject = {};
      let urlProject = `${BASE_URL_MANAGER}/project/${projectId}`;
      let urlProjectFlows = `${BASE_URL_MANAGER}/flow/${projectId}`;
      let urlProjectTopics = `${BASE_URL_MANAGER}/topic/${projectId}`;
      Axios(urlProject)
        .then((res) => {
          console.log("res.data", res.data);

          this.currentProject = res.data.project;
          this.isNew = res.data.isNew;
          if (
            this.currentProject.dropiUsers == undefined ||
            this.currentProject.dropiUsers.length == 0
          ) {
            this.currentProject.dropiUsers[0] = { token: "" };
          }
          this.getDropiWhatsappNumbers();
        })
        .catch((err) => {
          throw err;
        });
      Axios(urlProjectFlows)
        .then((res) => {
          this.topiContent = res.data.projectFlows;
          this.projectFlows = res.data.projectFlows.map((prFlow: any) => {
            return prFlow.name;
          });
        })
        .catch((err) => {
          throw err;
        });
      Axios(urlProjectTopics)
        .then((res) => {
          this.projectTopics = res.data.projectTopics.map((prTopic: any) => {
            return prTopic.name;
          });
        })
        .catch((err) => {
          throw err;
        });
      this.getConversations(projectId);
      this.projectId = projectId;
      this.visible = true;
    }
  }

  getConversations(projectId: any): void {
    const url = `${BASE_URL_ORCHESTATOR}/conversation/${projectId}`;
    Axios(url, {
      params: {
        limit: 10,
        pagenum: 0,
      },
    })
      .then((res) => {
        this.conversations = res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  handleSubmit(): void {
    const option: any = this.$refs.option;
    let url = `${BASE_URL_MANAGER}/project/${this.projectId}/initialFlow/${option.value}`;
    Axios.patch(url)
      .then((res) => {
        this.$notification.success({
          placement: "bottomRight",
          message: "Proyecto actualizado!",
          description: `El flujo del projecto ${res.data.project}, se ha modificado con exito!`,
        });
      })
      .catch((err) => {
        this.$notification.error({
          placement: "bottomRight",
          message: "Ocurrio un error!",
          description: `El flujo ${option.value} no se ha modificado!`,
        });
        throw err;
      });
  }

  handleSubmitDropi(): void {
    const option: any = this.$refs.option;
    let url = `${BASE_URL_MANAGER}/project/${this.projectId}`;

    this.currentProject.phoneNumberId = this.selectedWhatsappNumber.chanel;
    this.currentProject.wabaId = this.selectedWhatsappNumber.namespace;
    this.currentProject.wpBusinessAPiKey =
      this.selectedWhatsappNumber.access_key;
    this.currentProject.selectedWhatsappNumber = this.selectedWhatsappNumber.id;
    this.currentProject.integrator = this.selectedWhatsappNumber.integrator;

    Axios.put(url, this.currentProject)
      .then((res) => {
        this.$notification.success({
          placement: "bottomRight",
          message: "Proyecto actualizado!",
          description: `El projecto ${res.data.project.name}, se ha modificado con exito!`,
        });

        this.getDropiWhatsappNumbers();
      })
      .catch((err) => {
        this.$notification.error({
          placement: "bottomRight",
          message: "Ocurrio un error!",
          description: `El flujo ${option.value} no se ha modificado!`,
        });
        throw err;
      });
  }

  /***
   * obtengo las lienas de whatsapp business que tiene configuradas dropi
   */
  getDropiWhatsappNumbers(): void {
    let dropi_url = BASE_URL_DROPI;
    switch (this.currentProject!.country) {
      case "CO":
        dropi_url = "https://api.dropi.co/api";
        break;
      case "MX":
        dropi_url = "https://api.dropi.mx/api";
        break;
      case "MX":
        dropi_url = "https://api.dropi.pa/api";
        break;
      default:
        break;
    }
    const option: any = this.$refs.option;
    let url = `${dropi_url}/whatsapp-numbers/index`;

    Axios.get(url, {
      headers: {
        Authorization: "Bearer " + this.currentProject!.dropiUsers[0]!.token,
      },
    })
      .then((res: any) => {
        let data = res.data;
        this.whatsappNumbers = data.objects;
        if (this.whatsappNumbers != undefined) {
          this.selectedWhatsappNumber = this.whatsappNumbers.find(
            (e) => e.id == this.currentProject.selectedWhatsappNumber
          );
        }
      })
      .catch((err) => {
        this.$notification.error({
          placement: "bottomRight",
          message: "Ocurrio un error!",
          description: `El flujo ${option.value} no se ha modificado!`,
        });
        throw err;
      });
  }

  handleAdd(): void {
    this.projectsInfo.push({
      _id: Math.round(Math.random() * 10),
      name: "",
      editable: true,
    });
    this.addControl = true;
  }

  async proyectList(): Promise<void> {
    const url = `${BASE_URL_MANAGER}/project/company/${
      store.state.user!.user.company._id
    }`;

    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        console.log("response.data.projects", response.data);

        this.projectsInfo = response.data;
      }
    } catch (err) {
      this.$notification.error({
        placement: "bottomRight",
        message: "Ocurrio un error!",
        description: "No se pudo obtener la lista de proyectos",
      });
      throw err;
    }
  }

  // check if the project has a token
}
