import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import Axios, { AxiosError } from "axios";
import { BASE_URL_MANAGER } from "@/config";
import axios from "axios";
import Swal from "sweetalert2";
@Component({
  name: "facebook-button",
})
export default class FacebookButton extends Vue {
  @Prop() public project!: any;
  @Prop() public isNew!: boolean;
  public layout = "column";
  public labelCol = { span: 4 };
  public wrapperCol = { span: 14 };
  // Steps
  public steps = [
    {
      title: "Select Business",
    },
    {
      title: "Select Business Account",
    },
    {
      title: "Select Phone Number",
    },
    {
      title: "Validate Data",
    },
  ];

  public facebookUrl: string = "";
  public facebookToken: {
    accessToken: string;
    expiresIn: string;
    signedRequest: string;
    userID: string;
  } = {
    accessToken: "",
    expiresIn: "",
    signedRequest: "",
    userID: "",
  };
  public facebookId: string = "";
  public selectedBusiness: string = "";
  public enableBusiness: boolean = false;
  public enableBusinessAccount: boolean = false;
  public loading: boolean = false;
  public enablePhoneNumber: boolean = false;
  public existToken: boolean = false;
  public showAlert: boolean = false;
  public selectedPhoneNumberId: string = "";
  public selectedBusinessAccount: string = "";
  public businesses: {
    id: string;
    name: string;
  }[] = [];

  public businessAccounts: {
    id: string;
    name: string;
  }[] = [];

  public phoneNumbers: {
    id: string;
    display_phone_number: string;
  }[] = [];

  public async mounted() {
    await this.loadFacebookSdk();
    await this.initFacebook();
    await this.checkToken();
    await this.getLoginStatus();
  }

  async getLoginStatus() {
    window.FB.getLoginStatus((response: any) => {
      console.log(response);
      if (response.status === "connected") {
        this.facebookToken = response.authResponse;
        this.facebookId = response.authResponse.userID;
        this.getDataUser();
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    });
  }

  // public checkToken;

  public async checkToken() {
    const response = await axios.get(`${BASE_URL_MANAGER}/token/project`, {
      params: {
        projectID: this.project._id,
      },
    });
    console.log(response.data);

    // save the token in the local storage
    if (response.data.length > 0) {
      // code base64 token
      const token = response.data[0].accessToken;
      const expiresIn = response.data[0].expiresIn;
      const expirationTime = response.data[0].data_access_expiration_time;
      const dataAccessToken = {
        accessToken: token,
        expiresIn: expiresIn,
        expirationTime,
      };

      const base64Token = btoa(JSON.stringify(dataAccessToken));
      // this.existToken = true;
      this.showAlert = true;
      console.log("this.showAlert", this.showAlert);

      localStorage.setItem("token:facebook", base64Token);
    }

    console.log(response);
  }

  async logInWithFacebook() {
    window.FB.login(
      (response: any) => {
        console.log("Good to see you, " + JSON.stringify(response) + ".");
        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ");
          this.facebookToken = response.authResponse;
          this.facebookId = response.authResponse.userID;
          // this.$emit("facebookToken", this.facebookToken);          // this.$emit("facebookId", this.facebookId);
          this.getDataUser();
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope: [
          "whatsapp_business_messaging",
          "whatsapp_business_management",
          "business_management",
        ],
      }
    );
  }

  async getDataUser() {
    window.FB.api(
      "/me",
      { fields: "id,first_name,last_name,businesses" },
      (response: any) => {
        console.log("Good to see you, " + JSON.stringify(response) + ".");
        if (response) {
          this.businesses = response.businesses.data;
          this.existToken = true;
          console.log(response);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      }
    );
  }
  async handleChangesBusiness() {
    console.log(this.selectedBusiness);
    window.FB.api(
      `/${this.selectedBusiness}`,
      { fields: "owned_whatsapp_business_accounts" },
      (response: any) => {
        if (response) {
          console.log(response);
          this.businessAccounts =
            response.owned_whatsapp_business_accounts.data;
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      }
    );
  }

  async handleChangesBusinessAccount() {
    console.log(this.selectedBusinessAccount);
    window.FB.api(
      `/${this.selectedBusinessAccount}`,
      { fields: "phone_numbers" },
      (response: any) => {
        if (response) {
          this.phoneNumbers = response.phone_numbers.data;
          console.log(response);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      }
    );
  }
  async handleChangesPhoneNumberId() {
    if (this.selectedPhoneNumberId) {
      this.enablePhoneNumber = true;
    } else {
      this.enablePhoneNumber = false;
    }
  }

  async handleRevokeToken() {
    console.log(
      "        access_token: this.facebookToken.accessToken",
      this.facebookToken.accessToken
    );
    this.loading = true;
    window.FB.api(
      "/me/permissions",
      "DELETE",
      {
        access_token: this.facebookToken.accessToken,
      },
      async (response: any) => {
        await this.handleRemoveToken();
        localStorage.removeItem("token:facebook");
        this.existToken = false;
        if (response) {
          console.log(response);
          this.showAlert = false;
          this.loading = false;
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      }
    );
  }

  async handleRemoveToken() {
    const response = await axios.delete(
      `${BASE_URL_MANAGER}/token/project/${this.project._id}`
    );
    console.log(response);
    if (response) {
      console.log(response);
    } else {
      console.log("User cancelled login or did not fully authorize.");
    }
  }

  async verifyToken() {
    window.FB.api(
      "/me",
      { fields: "id,first_name,last_name,businesses" },
      (response: any) => {
        if (response) {
          console.log(response);
        } else {
          console.log("User cancelled login or did not fully authorize.");
          this.existToken = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Token is invalid",
          });
        }
      }
    );
  }
  // negate the value of enableBusiness
  handleChangesEnableBusiness() {
    if (this.selectedBusiness == "") {
      return;
    }
    this.enableBusiness = !this.enableBusiness;
  }

  // clear the value of selectedBusiness
  handleChangesClearBusiness() {
    this.selectedBusiness = "";
    this.enableBusiness = !this.enableBusiness;
  }

  // negate the value of enableBusinessAccount
  handleChangesEnableBusinessAccount() {
    if (this.selectedBusinessAccount == "") {
      return;
    }
    this.enableBusinessAccount = !this.enableBusinessAccount;
  }

  // clear the value of selectedBusinessAccount
  handleChangesClearBusinessAccount() {
    this.selectedBusinessAccount = "";
    this.enableBusinessAccount = !this.enableBusinessAccount;
  }

  // negate the value of enablePhoneNumber
  handleChangesEnablePhoneNumber() {
    this.enablePhoneNumber = !this.enablePhoneNumber;
  }

  // clear the value of selectedPhoneNumberId
  handleChangesClearPhoneNumber() {
    this.selectedPhoneNumberId = "";
    this.enablePhoneNumber = !this.enablePhoneNumber;
  }

  validateInputs(): boolean {
    if (
      this.selectedBusiness == "" ||
      this.selectedBusinessAccount == "" ||
      this.selectedPhoneNumberId == ""
    ) {
      return false;
    }
    return true;
  }

  async handleChangesSave() {
    if (!this.validateInputs()) {
      return;
    }
    console.log("projectId", this.project);

    const data = {
      facebookToken: this.facebookToken,
      businessId: this.selectedBusiness,
      bussinnes_account_id: this.selectedBusinessAccount,
      phone_number_id: this.selectedPhoneNumberId,
      project: this.project._id,
    };
    try {
      const response = await axios.post(`${BASE_URL_MANAGER}/token/`, data);
      console.log(response);
      if (response) {
        console.log(response);
        this.existToken = true;
        this.showAlert = true;
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Token saved",
        });
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    } catch (error) {
      const { message } = error as AxiosError;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      });
    }
  }



  async initFacebook() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: "552344496853354",
        cookie: true,
        xfbml: true,
        version: "v3.3",
      });

      window.FB.AppEvents.logPageView();
    };
  }

  async loadFacebookSdk() {
    return new Promise((resolve, reject) => {
      // check if facebook sdk is already loaded
      if (document.getElementById("facebook-jssdk")) {
        resolve(true);
        return;
      } else {
        const id = "facebook-jssdk";
        const fjs = document.getElementsByTagName("script")[0];
        if (document.getElementById(id)) {
          resolve(true);
          return;
        }
        const js = document.createElement("script");
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        js.onload = resolve;
        js.onerror = reject;
        fjs.parentNode!.insertBefore(js, fjs);
      }
    });
  }
  // handler local storage token Facebook
  async handlerToken() {
    const token = localStorage.getItem("token:facebook");
    if (token) {
      // token base64 decode
      const decodedToken = atob(token);
      // token json parse
      const parsedToken = JSON.parse(decodedToken);
      console.log(parsedToken);
      this.verifyToken();
    }
  }

  // handler steps
  currentStep = 0;

  nextStep1() {
    this.currentStep++;
    this.selectedBusinessAccount = "";
    console.log(this.currentStep);
  }
  nextStep2() {
    this.currentStep++;
    this.selectedPhoneNumberId = "";
  }
  nextStep3() {
    this.currentStep++;
  }

  prevStep1() {
    this.currentStep--;
    this.selectedBusiness = "";
  }
  prevStep2() {
    this.currentStep--;
    this.selectedBusinessAccount = "";
  }
  prevStep3() {
    this.currentStep--;
    this.selectedPhoneNumberId = "";
  }
}
